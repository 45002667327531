import React from 'react';
import { useLocation, useRoutes } from "react-router-dom";
import Dashboard from './pages/Dashboard';

function App() {
    const location = useLocation();
    
    let element = useRoutes([
        { path: '/', element: <Dashboard /> },
    ],
    location);

    return element;
}

export default App;
