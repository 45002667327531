import React, { useState } from 'react';

type Props = {
    children?: JSX.Element | JSX.Element[];
};

function AppContainer({ children }: Props) {

    return <>
            <div className="relative flex h-full min-h-screen w-full">
                <div className="flex flex-1"><div className="w-full max-h-screen snap-y snap-mandatory h-screen overflow-y-scroll">{children}</div></div>
            </div>
    </>;
}

export default AppContainer;
